<template>
  <div>
    <div class="home-frame">
      <mdb-carousel
          :items="items"
          :interval="5000"
          indicators
      ></mdb-carousel>
      <div class="title-box">
        <div class="title-real">
          <h1>Child's health depends on<br/>
            participation in daily activities.
          </h1>
          <mdb-btn color="" class="home-btn" @click="goLogin"><p>지금 바로 K-COSA 평가 시행하기</p><img src="@/assets/images/IA/home_btn_icon.png" alt=""></mdb-btn>
        </div>
      </div>
    </div>
    <div class="home-bottom">
      <div class="home-bottom-box inner">
        <div class="home-text-box">
          <h5>아동용 생활 참여 평가</h5>
          <p>아동의 건강은 신체기능 향상이 아닌 <br/>의미 있는 활동 참여에 달려있습니다. <br/>
            재활서비스의 최종목표는 아동이 발달과 <br/>역할에 맞는 활동에 참여하는 것 입니다.</p>
          <p>재활서비스의 첫 단계!<br/>
            <span>아동의 일상생활활동 참여를 평가하는 것입니다.</span></p>
        </div>
        <ul class="home-icon">
          <li>
            <h5>스스로 옷 입고 벗기</h5>
            <p><img src="@/assets/images/IA/home_icon_1.png" alt=""></p>
            <div class="check-box">
              <img src="@/assets/images/IA/home_icon_5.png" alt="">
              <p>정말 잘해요</p>
            </div>
          </li>
          <li>
            <div><img class="img-group" src="@/assets/images/IA/home_icon_4.png" alt=""></div>
          </li>
          <li>
            <h5>내 물건을 정리 정돈하기</h5>
            <p><img src="@/assets/images/IA/home_icon_2.png" alt=""></p>
            <div class="check-box">
              <img src="@/assets/images/IA/home_icon_6.png" alt="">
              <p>조금 중요해요</p>
            </div>
          </li>
          <li>
            <div><img class="img-group" src="@/assets/images/IA/home_icon_4.png" alt=""></div>
          </li>
          <li>
            <h5>교실(학교) 규칙 지키기</h5>
            <p><img src="@/assets/images/IA/home_icon_3.png" alt=""></p>
            <div class="check-box">
              <img src="@/assets/images/IA/home_icon_7.png" alt="">
              <p>잘하는 편이에요</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {mdbCarousel, mdbBtn,} from "mdbvue";
import bg_1 from '@/assets/images/IA/slider_img_1.png';
import bg_2 from '@/assets/images/IA/slider_img_2.png';
import bg_3 from '@/assets/images/IA/slider_img_3.png';

export default {
  name: 'Home',
  components: {
    mdbCarousel, mdbBtn,
  },
  data() {
    return {
      bg_1_src: bg_1,
      items: [
        {
          img: true,
          src: bg_1,
        },
        {
          img: true,
          src: bg_2,
        },
        {
          img: true,
          src: bg_3,
        },
      ]
    };
  },
  methods: {
    goLogin() {
      this.$router.push({path: '/login'}).catch(() => true)
    },
  }
};
</script>
<style scoped>

.home-frame {
  position: relative;
  max-width: 1920px;
  max-height: 1000px;
  margin: 0 auto;
  overflow: hidden;
  color: #FFFFFF;
  object-fit: contain;
}

.home-frame .overlay {
  height: 110%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
}

.title-box {
  position: absolute;
  width: 100%;
  z-index: 50;
  display: flex;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);
}

.title-real {
  width: 820px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  font-family: 'Lexend_SB', serif;
  font-weight: 600;
  font-size: 48px;
  line-height: 150%;
}

.home-btn {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  width: 380px;
  height: 48px;
  padding: 0;
  background: #4C9AF5;
  border-radius: 6px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
}

.home-btn:hover {
  color: #ffffff;
}

.home-btn img {
  margin-left: 10px;
  width: 26px;
  height: 26px;
}

.home-bottom {
  height: 617px;
  color: #ffffff;
  background: #4C9AF5;
  display: flex;
  align-items: center;
}

.home-bottom-box {
  width: 1139px;
  height: 417px;
  position: relative;
  font-family: 'Elice_B', serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.home-text-box {
  width: 401px;
}

.home-text-box h5 {
  margin-bottom: 59px;
  font-size: 40px;
  line-height: 32px;
}

.home-text-box p {
  font-family: 'Elice', serif;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 30px;
}

.home-text-box span {
  font-family: 'Elice_B', serif;
  font-size: 20px;
  color: #F7D300;
}

.home-bottom-box ul {
  background: #72B3FE;
  width: 670px;
  height: 319px;
  border-radius: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  line-height: 20px;
  padding: 35px 35px;
}

.home-bottom-box ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 249px;
}

.home-bottom-box ul li .check-box {
  width: 151px;
  height: 93px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  color: black;
}

.home-bottom-box ul li > p {
  height: 116px;
  margin: 10px 0;
}

.home-bottom-box ul li p img {
  height: 116px;
}

.home-bottom-box ul li .check-box p {
  margin-top: 10px;
}

.home-bottom-box ul li .check-box img {
  width: 60px;
  height: 38px;
}
</style>
